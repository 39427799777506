<template>
  <div>
    <v-btn
      color="borderColor"
      outlined
      class="sub-body-bold"
      t-data="export-dialog-btn"
      @click="show = true"
    >
    <v-icon color="primaryTextColor" left>
        mdi-file-excel-outline
    </v-icon>
    <div class="text-btn">
      EXPORT
    </div>
  </v-btn>
    <v-dialog v-model="show" persistent max-width="455">
      <v-card>
        <v-card-title class="headline text-truncate pb-6" t-data="dialog-title">
          <h4>
            <span>Export Report</span>
          </h4>
        </v-card-title>
        <v-card-text class="pb-6">
          <v-alert
            v-if="isFail"
            class="alert-container"
            t-data="alert-container"
            color="info200Color"
            dense
            icon="mdi-information"
          >
            You can export up to {{ convertNumToComma(limitExport) }} results max.</v-alert>
          <div class="card-text-container py-2 px-3" t-data="dialog-content"><b>{{ total }}</b> results will be exported.</div>
          <v-alert
            v-if="isFail"
            class="alert-footer-container"
            t-data="alert-footer-container"
            color="danger600Color"
            dense
            icon="mdi-information"
            outlined
            type="success"
          >Export results exceed the limit. Please refine filters and try again.</v-alert>
        </v-card-text>
        <v-divider class="divider-bg"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="alert-cancel"
            class="cancel-button button-bold"
            t-data="cancel-btn"
            color="info600Color"
            text
            @click="show = false">
            Cancel
          </v-btn>
          <v-btn
            id="alert-confirm"
            class="confirm-button button-bold"
            t-data="export-btn"
            color="info600Color"
            text
            @click="downloadCSVData"
            :disabled="isFail"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {limitExport} from '@/constants/order'
import {convertIntegerToCommaNumber} from '@/helper/helper'

export default {
  name: 'ExportCsvButton',
  data: () => ({
    show: false,
    limitExport
  }),
  props: {
    filename: {type: String},
    data: {type: String},
    total: {type: Number},
    isFail: {
      type: Boolean,
      default: false,
      required: false,
    },
    fetchExportData: {type: Function}
  },
  methods: {
    async downloadCSVData() {
      await this.fetchExportData()
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.data);
      anchor.target = '_blank';
      anchor.download = `${this.filename}.csv`;
      anchor.click();
    },
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
  }
}
</script>

<style lang="scss" scoped>
.text-btn {
  color: var(--primary-text-color) !important;
}
.card-text-container {
  background: var(--fail-chip-color);
  font-size: 1rem;
  border-radius: 4px;
}
.alert-container {
  color: var(--info-800-color) !important;
  font-size: 0.875rem;
  padding: 6px 10px;
}
.alert-footer-container {
  font-size: 0.75rem;
  border-style: none !important;
  padding: 0;
  margin: 0;
}

</style>

<style lang="scss">
.alert-container > .v-alert__wrapper > .theme--light.v-icon {
  color: var(--info-800-color) !important;
  font-size: 1.25rem;
  margin-right: 10px;
}
.alert-footer-container > .v-alert__wrapper > .theme--light.v-icon {
  font-size: 0.75rem;
  margin-right: 0;
}
</style>
