<template>
  <div class="branch-multiple-selecter">
    <p class="select-label sub-body-bold" t-data="headline">{{ title }}</p>
    <v-select
      :class="{'selector-filter': hideDetail, 'disable-selector': isDisabled, 'can-disable-selector': true}"
      :placeholder="placeholder"
      :items="branch"
      outlined
      required
      :rules="rules"
      color="primary500Color"
      dense
      clearable
      multiple
      small-chips
      v-model="selectedChild"
      item-text="codename"
      item-value="selected"
      :menu-props="menuProps"
      :disabled="isDisabled"
    >
      <template v-slot:prepend-item v-if="multiple">
        <v-list-item>
        <div class="branch-list">
          <v-list-item
            ripple
            @mousedown.prevent
            @click="toggle"
          >
            <v-list-item-icon>
              <v-icon :color="selectedChild && selectedChild.length > 0 ? 'secondary' : ''">
              {{ icon }}
            </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="chip-text body-regular">Select All</v-list-item-title>
            <v-spacer></v-spacer>
          </v-list-item>
        </div>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>

      <template v-slot:selection="{attrs, item, selected}">
        <span v-if="isDisabled" class="chip-text body-regular">{{ item.codename }}</span>
        <v-chip
          v-else
          v-bind="attrs"
          color="chip"
          :input-value="selected"
          class="chip-text body-regular chip-color"
          @click="removeBranch(item)"
        >
          {{ item.codename }}
        </v-chip>
      </template>

      <template v-slot:item="{item}" class="pl-0">
        <div class="branch-list">
          <v-list-item>
            <v-list-item-icon>
              <v-icon
                v-if="selectedChild && selectedChild.includes(item.codename)"
                color="secondary"
              >
                mdi-checkbox-marked
              </v-icon>
              <v-icon
                v-if="!selectedChild || !selectedChild.includes(item.codename)"
              >
                mdi-checkbox-blank-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="chip-text body-regular">
              {{ item.codename }}
            </v-list-item-title>
            <v-spacer></v-spacer>
          </v-list-item>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'MultipleSelected',
  data() {
    return {
      selectedChild:
        this.selectedBranch && this.selectedBranch.length > 0
          ? this.selectedBranch
          : null,
      rules: this.rule ? [this.rule] : [this.selectedRules],
    }
  },
  props: {
    selectedBranch: {type: Array},
    branch: {type: Array},
    title: {type: String},
    syncData: {type: Function},
    rule: {
      type: Function,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select Branch",
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isChanged: {
      type: Boolean,
      default: false,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideDetail: {
      type: Boolean,
      default: true,
      required: false,
    }
  },
  methods: {
    selectedRules() {
      if (this.selectedChild && this.selectedChild.length > 0) {
        return true
      } else {
        return 'Branch is required'
      }
    },
    removeBranch(value) {
      this.selectedChild = this.selectedChild.filter(child => value.codename !== child)
    },
    toggle () {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.selectedChild = []
        } else {
          this.selectedChild = this.branch.map(v => v.codename).slice()
        }
      })
    },
  },
  mounted() {
    if (this.selectedChild) {
      this.selectedChild = this.selectedBranch
        .map(({codename, selected}) => (selected ? codename : null))
        .filter(v => v !== null)
    }
  },
  computed: {
    menuProps() {
      if (this.multiple) return {overflowY: true}
      return this.selectedChild && this.selectedChild.length > 0
        ? {value: false, overflowY: true}
        : {overflowY: true}
    },
    selectedAll () {
      return this.selectedChild && this.selectedChild.length === this.branch.length
    },
    selectedSome () {
      return this.selectedChild && this.selectedChild.length > 0 && !this.selectedAll
    },
    icon () {
      if (this.selectedAll) return 'mdi-close-box'
      if (this.selectedSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    isChanged() {
      if (this.isChanged) this.selectedChild = this.selectedBranch
        .map(({codename, selected}) => (selected ? codename : null))
        .filter(v => v !== null)
    },
    selectedChild() {
      this.$emit('resetIsChanged')
      if (this.selectedChild) {
        this.syncData(this.selectedChild)
      }
    },
  }
}
</script>
<style scoped>
.select-label {
  color: var(--primary-text-color);
  margin-bottom: 0;
}

.chip-label {
  color: var(--info-800-color);
}

.chip-text {
  text-transform: uppercase;
}

.chip-color {
  color: var(--info-800-color);
}

.branch-list {
  width: 100%;
}
</style>

<style>

.disable-selector > .v-input__control > .v-input__slot {
  background: var(--fail-chip-color);
}
</style>