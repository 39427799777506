<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="ml-5 mr-5 fixWidth d-block">
        <v-multiple-selected
          t-data="selector-filter"
          title="Branch"
          placeholder="All Branches"
          :branch="orgList.all"
          :selectedBranch="orgList.selected"
          :syncData="syncListSelectedData"
          :rule="() => true"
          :multiple="true"
          :is-changed="isChanged"
          :is-disabled="hasOneOrg"
          @resetIsChanged="isChanged = false"
        ></v-multiple-selected>
      </v-list-item>

      <v-list-item
        class="mt-2 ml-5 mr-5 fixWidth d-block"
        t-data="order-channel-filter"
      >
        <p class="mb-0 filterContentTitle sub-body-bold">Channel</p>
        <v-select
          class="selector-filter"
          t-data="channel-selector"
          placeholder="All Channels"
          :items="channelList"
          outlined
          required
          color="primary500Color"
          dense
          v-model="channelSelected"
          item-text="label"
          item-value="value"
        ></v-select>
      </v-list-item>

      <v-list-item
        class="mt-2 ml-5 mr-5 fixWidth d-block"
        t-data="order-date-filter"
      >
        <p class="mb-0 filterContentTitle sub-body-bold">Order Date</p>
        <custom-date-picker
          placeholder=""
          v-model="orderedDateSelected"
          :rule="[() => true]"
          outlined
          required
        ></custom-date-picker>
      </v-list-item>

      <v-list-item
        class="mt-2 ml-5 mr-5 fixWidth d-block"
        t-data="payment-success-date-filter"
      >
        <p class="mb-0 filterContentTitle sub-body-bold">
          Payment Success Date
        </p>
        <custom-date-picker
          placeholder=""
          v-model="paymentSuccessDateSelected"
          :rule="[() => true]"
          outlined
          required
        ></custom-date-picker>
      </v-list-item>

      <v-list-item
        class="mt-2 ml-5 mr-5 fixWidth d-block"
        t-data="refunded-date-filter"
      >
        <p class="mb-0 filterContentTitle sub-body-bold">Refunded Date</p>
        <custom-date-picker
          placeholder=""
          v-model="refundedDateSelected"
          :rule="[() => true]"
          outlined
          required
        ></custom-date-picker>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import MultipleSelected from '@/components/MultipleSelected'
import FilterButton from '@/components/FilterButton'
import DatePickerRange from '@/components/DatePickerRange'
import {convertSelectedData} from '@/helper/helper'
import {channelText} from '@/constants/order'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    channelSelected: null,
    orderedDateSelected: [],
    paymentSuccessDateSelected: [],
    refundedDateSelected: [],
    countFiltered: 0,
    isChanged: false,
    appliedBranches: [],
    appliedChannel: null,
    appliedPaymentSuccessDate: [],
    appliedRefundedDate: [],
    appliedOrderedDate: []
  }),
  components: {
    FilterButton,
    'v-multiple-selected': MultipleSelected,
    'custom-date-picker': DatePickerRange
  },
  computed: {
    isClearFilter() {
      const changedBranch = !_.isEqual(this.orgList.selected, [])
      const changedChannel = !_.isEqual(this.channelSelected, null)
      const changedPaymentSuccessDate = !_.isEqual(
        this.paymentSuccessDateSelected,
        []
      )
      const changedRefundedDate = !_.isEqual(this.refundedDateSelected, [])
      const changedOrderedDate = !_.isEqual(this.orderedDateSelected, [])
      if (this.hasOneOrg)
        return (
          changedChannel ||
          changedPaymentSuccessDate ||
          changedRefundedDate ||
          changedOrderedDate
        )
      return (
        changedBranch ||
        changedChannel ||
        changedPaymentSuccessDate ||
        changedRefundedDate ||
        changedOrderedDate
      )
    },
    isChangeFilter() {
      const changedBranch = !_.isEqual(
        this.orgList.selected,
        this.appliedBranches
      )
      const changedChannel = !_.isEqual(
        this.channelSelected,
        this.appliedChannel
      )
      const changedPaymentSuccessDate = !_.isEqual(
        this.paymentSuccessDateSelected,
        this.appliedPaymentSuccessDate
      )
      const changedRefundedDate = !_.isEqual(
        this.refundedDateSelected,
        this.appliedRefundedDate
      )
      const changedOrderedDate = !_.isEqual(
        this.orderedDateSelected,
        this.appliedOrderedDate
      )
      if (this.hasOneOrg)
        return (
          changedChannel ||
          changedPaymentSuccessDate ||
          changedRefundedDate ||
          changedOrderedDate
        )
      return (
        changedBranch ||
        changedChannel ||
        changedPaymentSuccessDate ||
        changedRefundedDate ||
        changedOrderedDate
      )
    },
    hasOneOrg() {
      return this.orgList.all.length === 1
    },
    channelList() {
      const types = []
      for (let key in channelText) {
        if (channelText.hasOwnProperty(key)) {
          if(key == 'ALL'){
            types.push({label: 'All Channels', value: key})
          }else
            types.push({label: channelText[key], value: key})
        }
      }
      return types
    },
    ...mapState('order', ['orgList'])
  },
  methods: {
    sumFiltered() {
      const sumBranch =
        this.hasOneOrg || this.orgList.selected.length <= 0 ? 0 : 1
      const sumChannel = this.channelSelected === null ? 0 : 1
      const sumPaymentSuccessDate =
        this.paymentSuccessDateSelected.length > 0 ? 1 : 0
      const sumRefundedDate = this.refundedDateSelected.length > 0 ? 1 : 0
      const sumOrderedDate = this.orderedDateSelected.length > 0 ? 1 : 0
      return (
        sumBranch +
        sumChannel +
        sumPaymentSuccessDate +
        sumRefundedDate +
        sumOrderedDate
      )
    },
    syncListSelectedData(data) {
      this.orgList.selected = []
      this.orgList.selected = convertSelectedData(
        data,
        this.orgList.all,
        this.orgList.selected
      )
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedBranches = this.orgList.selected
      this.appliedChannel = this.channelSelected
      this.appliedPaymentSuccessDate = this.paymentSuccessDateSelected
      this.appliedRefundedDate = this.refundedDateSelected
      this.appliedOrderedDate = this.orderedDateSelected
      this.setFilterChannel(this.channelSelected)
      this.setFilterPaymentSuccessDate(this.paymentSuccessDateSelected)
      this.setFilterRefundedDate(this.refundedDateSelected)
      this.setFilterOrderedDate(this.orderedDateSelected)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      if (!this.hasOneOrg) this.orgList.selected = []
      this.channelSelected = null
      this.paymentSuccessDateSelected = []
      this.refundedDateSelected = []
      this.orderedDateSelected = []
      this.isChanged = true
    },
    handleCancelFilter() {
      if (!this.hasOneOrg) {
        this.orgList.selected = this.appliedBranches
        this.isChanged = true
      }
      // wait for multiple select render
      this.$nextTick(() => {
        this.channelSelected = this.appliedChannel
        this.paymentSuccessDateSelected = this.appliedPaymentSuccessDate
        this.refundedDateSelected = this.appliedRefundedDate
        this.orderedDateSelected = this.appliedOrderedDate
        this.setFilterChannel(this.channelSelected)
        this.setFilterPaymentSuccessDate(this.paymentSuccessDateSelected)
        this.setFilterRefundedDate(this.refundedDateSelected)
        this.setFilterOrderedDate(this.orderedDateSelected)
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('order', [
      'fetch',
      'setFilterChannel',
      'setFilterOrderedDate',
      'setFilterPaymentSuccessDate',
      'setFilterRefundedDate',
    ])
  }
}
</script>
