<template>
  <v-chip
    t-data="status-chip"
    :text-color="statusTextColor(status)"
    :color="statusFillColor(status)"
    dark
  >
    {{ statusName(status) }}
  </v-chip>
</template>

<script>
import {orderStatus} from '@/constants/order'

export default {
  name: 'OrderStatusChip',
  props: {
    status: {type: String}
  },
  methods: {
    statusTextColor(status) {
      if (status === 'WAITING_FOR_PAYMENT') return 'primaryTextColor'
      if (status === 'IN_PROGRESS') return 'primaryTextColor'
      if (status === 'COMPLETED') return 'success800Color'
      if (status === 'CANCELLED') return 'danger700Color'
      if (status === 'REFUNDED') return 'info800Color'
    },
    statusFillColor(status) {
      if (status === 'WAITING_FOR_PAYMENT') return 'failChip'
      if (status === 'IN_PROGRESS') return 'failChip'
      if (status === 'COMPLETED') return 'success300Color'
      if (status === 'CANCELLED') return 'danger300Color'
      if (status === 'REFUNDED') return 'info200Color'
    },
    statusName(status) {
      const listStatus = Object.entries(orderStatus).map(s => ({value: s[0], name: s[1]}))
      const selectedStatus = listStatus.filter(s => s.value === status)
      if (selectedStatus.length > 0) return selectedStatus[0].name
      return status
    },
  }
}
</script>
<style lang="sass" scoped>
</style>
