<template>
  <v-tooltip class="px-2" color="rgba(0, 0, 0, 0.85)" top>
    <template v-slot:activator="{on, attrs}">
      <div v-bind="attrs" v-on="on">
        <slot name="content"> </slot>
      </div>
    </template>
    <div class="body-regular" t-data="tooltip-th-content">
      <div class="pa-0" v-for="(item, index) in item.line_items" :key="index">
        <div v-if="isPackageGroup(item)" t-data="tooltip-group-content">
          <div>
            {{
              item.product_parent_contents[0].code === 'th'
                ? item.product_parent_contents[0].title
                : item.product_parent_contents[1].title
            }}
          </div>
          <div style="height: 30px">
            <div class="d-inline-block text-truncate" style="width: 190px">
              &bull;
              {{
                item.product_contents[0].code === 'th'
                  ? item.product_contents[0].title
                  : item.product_contents[1].title
              }}
            </div>
          </div>
          <div>
            {{
              item.product_parent_contents[0].code === 'en'
                ? item.product_parent_contents[0].title
                : item.product_parent_contents[1].title
            }}
          </div>
          <div style="height: 30px">
            <div class="d-inline-block text-truncate" style="width: 190px">
              &bull;
              {{
                item.product_contents[0].code === 'en'
                  ? item.product_contents[0].title
                  : item.product_contents[1].title
              }}
            </div>
          </div>
        </div>
        <div v-else t-data=tooltip-single-content>
          <div>
            {{
              item.product_contents[0].code === 'th'
                ? item.product_contents[0].title
                : item.product_contents[1].title
            }}
          </div>
          <div>
            {{
              item.product_contents[0].code === 'en'
                ? item.product_contents[0].title
                : item.product_contents[1].title
            }}
          </div>
        </div>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
import {productTypeEnum} from '@/constants/order'

export default {
  name: 'TooltipTitle',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    isPackageGroup(lineItem) {
      if (lineItem.product_type === productTypeEnum.PACKAGES) {
        return 'product_parent_title_th' in lineItem
      } else {
        return false
      }
    },
    getTitleByCode(productContent, code) {
      const products = productContent.filter(p => p.code === code)
      return products.length > 0 ? products[0].title : ''
    }
  }
}
</script>
<style scoped>
.v-tooltip__content {
  padding: 8px 16px !important;
}
</style>
