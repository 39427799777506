<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{on}">
      <v-text-field
        class="date-picker-range"
        outlined
        v-model="dateRangeText"
        :label="label"
        :placeholder="placeholder"
        append-icon="event"
        readonly
        dense
        :rules="rules"
        v-on="on"
        :disabled="isDisable"
        :clearable="isClearable"
        @click:clear="clear()"
      ></v-text-field>
    </template>
    <v-date-picker
      reactive
      v-model="dates"
      no-title
      scrollable
      :allowed-dates="setAllowedDates"
      @input="update"
      @change="$refs.menu.save(dates)"
      :max="new Date().toISOString().substr(0, 10)"
      range
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import {convertDateBirthDate} from '@/helper/helper'
import moment from 'moment'

export default {
  props:
    {
    value: {type: Array},
    label: {type: String},
    allowedDates: {type: Function},
    rule: {type: Array},
    placeholder: {type: String},
    isDisable: {type: Boolean},
    isClearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    update() {
      if (this.dates.length === 2) {
        this.$emit('input', this.dates)
      }
    },
    clear() {
      this.dates = []
      this.update()
    },
    allowedRangeDates(val) {
      if (this.dates.length === 1) {
        return moment(this.dates[0]).isSameOrBefore(
          moment(val)
        )
      }
      return val
    },
  },
  watch: {
    value() {
      this.dates = this.value
    },
  },
  data() {
    return {
      menu: false,
      dates: this.value,
      rules: this.rule || [v => !!v || 'Date is required'],
      setAllowedDates: this.allowedDates || this.allowedRangeDates
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.map(d => convertDateBirthDate(d)).join(' - ')
    },
  },
}
</script>

<style>
.date-picker-range > .v-input__control > .v-text-field__details {
  display: none;
}

</style>