<template>
  <span
    t-data="status-label"
    :class="`${statusTextColor(status)}--text`"
    dark
  >
    <span t-data="dot-label" :class="`${dotTextColor(status)}--text`">&bull; </span>{{ statusName(status) }}
  </span>
</template>

<script>
import {orderPaymentStatus} from '@/constants/order'

export default {
  name: 'PaymentStatus',
  props: {
    status: {type: String}
  },
  methods: {
    dotTextColor(status) {
      if (status === 'NEW') return 'disableTextColor'
      if (status === 'AWAITING_PAYMENT_CHECKOUT') return 'disableTextColor'
      if (status === 'IN_PROGRESS') return 'disableTextColor'
      if (status === 'PENDING') return 'disableTextColor'
      if (status === 'SUCCESS') return 'success500Color'
      if (status === 'FAILED') return 'danger500Color'
      if (status === 'CANCELLED') return 'danger500Color'
      if (status === 'EXPIRED') return 'danger500Color'
      if (status === 'REFUNDED') return 'info600Color'
    },
    statusTextColor(status) {
      if (status === 'NEW') return 'primaryTextColor'
      if (status === 'AWAITING_PAYMENT_CHECKOUT') return 'primaryTextColor'
      if (status === 'IN_PROGRESS') return 'primaryTextColor'
      if (status === 'PENDING') return 'primaryTextColor'
      if (status === 'SUCCESS') return 'success600Color'
      if (status === 'FAILED') return 'danger500Color'
      if (status === 'CANCELLED') return 'danger500Color'
      if (status === 'EXPIRED') return 'danger500Color'
      if (status === 'REFUNDED') return 'info600Color'
    },
    statusName(status) {
      const listStatus = Object.entries(orderPaymentStatus).map(s => ({value: s[0], name: s[1]}))
      const selectedStatus = listStatus.filter(s => s.value === status)
      if (selectedStatus.length > 0) return selectedStatus[0].name
      return status
    },
  }
}
</script>
<style lang="sass" scoped>
</style>
