<template>
  <v-container class="px-10" fluid>
    <v-popup-loading :isLoading="isLoadingExport" />

    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="linkRoute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">Order</h3>
        </div>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" t-data="search-panel">
            <search
              :search="search"
              label="Search by Order No., Invoice No., Buyer Name or Mobile No."
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-col cols="12" sm="4"></v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex justify-end align-center"
            t-data="filter-panel"
          >
            <export-csv-button
              class="mr-4"
              :filename="'Sale report'"
              :data="orderCsvData"
              :total="total"
              :is-fail="total > limitExport"
              :fetchExportData="handleExport"
            ></export-csv-button>
            <filter-dropdown @apply="handleApplyFilter()"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs
        v-model="selectedStatus"
        @change="handleChangeStatus"
        class="status-tab body-regular"
        color="primary500Color"
        slider-color="primary500Color"
        align-with-title
      >
        <v-tab
          t-data="tab-panel"
          v-for="status in listStatus"
          :key="status.value"
        >
          {{ status.name }}
        </v-tab>
      </v-tabs>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          t-data="data-table"
          :headers="headers"
          :items="orderList"
          :loading="isLoading"
          :options.sync="options"
          :items-per-page="pageSize"
          :server-items-length="total"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          item-key="id"
          :sort-desc="true"
          :must-sort="false"
          class="elevation-1 custom-table order-table"
          @click:row="handlerClick"
          fixed-header
          style="width: 100%"
          :height="
            windowSize.y - heightOptions.header - heightOptions.tableFooter
          "
        >
          <template v-slot:header.total_price="{header}">
            Sale Price
            <br />
            (Baht)
          </template>
          <template v-slot:item.create_date="{item}">
            {{ dateFmt(item.create_date) }}</template
          >
          <template v-slot:item.line_items="{item}">
            <tooltip-title :item="item">
              <template v-slot:content>
                <v-list-item-content
                  v-for="(item, index) in item.line_items"
                  :key="index"
                  class="item-content"
                  style="line-height: 24px"
                >
                  <div
                    class="d-inline-block text-truncate"
                    style="width: 190px"
                    v-if="isPackageGroup(item)"
                  >
                    <v-list-item-title style="line-height: 24px">
                      {{
                        item.product_parent_contents[0].code === 'th'
                          ? item.product_parent_contents[0].title
                          : item.product_parent_contents[1].title
                      }}
                      <div style="height: 30px">
                        <div
                          class="d-inline-block text-truncate"
                          style="width: 190px"
                        >
                          &bull;
                          {{
                            item.product_contents[0].code === 'th'
                              ? item.product_contents[0].title
                              : item.product_contents[1].title
                          }}
                        </div>
                      </div>
                    </v-list-item-title>
                    <v-list-item-title style="line-height: 24px">
                      {{
                        item.product_parent_contents[0].code === 'en'
                          ? item.product_parent_contents[0].title
                          : item.product_parent_contents[1].title
                      }}
                      <div style="height: 30px">
                        <div
                          class="d-inline-block text-truncate"
                          style="width: 190px"
                        >
                          &bull;
                          {{
                            item.product_contents[0].code === 'en'
                              ? item.product_contents[0].title
                              : item.product_contents[1].title
                          }}
                        </div>
                      </div>
                    </v-list-item-title>
                  </div>
                  <div
                    class="d-inline-block text-truncate"
                    style="width: 190px"
                    v-else
                  >
                    <v-list-item-title style="line-height: 24px">
                      {{
                        item.product_contents[0].code === 'th'
                          ? item.product_contents[0].title
                          : item.product_contents[1].title
                      }}
                    </v-list-item-title>
                    <v-list-item-title style="line-height: 24px">
                      {{
                        item.product_contents[0].code === 'en'
                          ? item.product_contents[0].title
                          : item.product_contents[1].title
                      }}
                    </v-list-item-title>
                  </div>
                </v-list-item-content>
              </template>
            </tooltip-title>
          </template>
          <template v-slot:item.user_first_name="{item}">
            <div class="d-inline-block text-truncate" style="width: 120px">
              {{ item.user_first_name }} {{ item.user_last_name }}
            </div>
          </template>
          <template v-slot:item.payment_channel="{item}">{{
            item.payment_channel in channelTextItems
              ? channelTextItems[item.payment_channel]
              : ''
          }}</template>
          <template v-slot:item.org_codename="{item}">{{
            item.org_codename.toUpperCase()
          }}</template>
          <template v-slot:item.payment_method="{item}">{{
            paymentMethodItems[item.payment_method]
          }}</template>
          <template v-slot:item.total_price="{item}">
            ฿ {{ convertNumToComma(item.total_price) }}
          </template>
          <template v-slot:item.status="{item}">
            <order-status-chip :status="item.status"></order-status-chip>
          </template>
          <template v-slot:item.payment_status="{item}">
            <payment-status :status="item.payment_status"></payment-status>
          </template>
          <template v-slot:item.paid_at="{item}">
            {{ item.paid_at === null ? '' : dateFmt(item.paid_at) }}</template
          >
          <template v-slot:item.refunded_at="{item}">
            {{
              item.refunded_at === null ? '' : dateFmt(item.refunded_at)
            }}</template
          >
          <template v-slot:item.update_date="{item}">
            {{ dateFmt(item.update_date) }}</template
          >
        </v-data-table>
      </v-layout>
      <div class="table-footer-prepend" t-data="total-result-content">
        <div class="vertical-center pl-4">
          <b>{{ total }}</b> Search results
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import {
  convertDate,
  convertDateBirthDate,
  convertIntegerToCommaNumber,
  searchHandler
} from '../../helper/helper'
import Search from '../../components/Search'
import FilterDropdown from './FilterDropdown'
import {
  channelText,
  limitExport,
  orderStatus,
  paymentMethod,
  productTypeEnum
} from '@/constants/order'
import OrderStatusChip from '@/components/order/OrderStatusChip'
import PaymentStatus from '@/components/order/PaymentStatus'
import TooltipTitle from '@/components/order/TooltipTitle'
import ExportCsvButton from '@/components/ExportCsvButton'
import PopupLoading from '@/components/PopupLoading'

export default {
  components: {
    'v-popup-loading': PopupLoading,
    ExportCsvButton,
    FilterDropdown,
    OrderStatusChip,
    PaymentStatus,
    Search,
    TooltipTitle
  },
  name: 'list',
  data() {
    return {
      heightOptions: {
        header: 360,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      limitExport,
      orderCsvData: '',
      linkRoute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Order',
          disabled: true,
          href: '/'
        }
      ],
      search: '',
      options: {},
      page: 1,
      headers: [
        {
          text: 'Order No.',
          align: 'left',
          value: 'order_number',
          sortable: true,
          width: 190
        },
        {
          text: 'Invoice No.',
          align: 'left',
          value: 'payment_id',
          sortable: true,
          width: 210
        },
        {
          text: 'Buyer Name',
          align: 'left',
          value: 'user_first_name',
          class: 'text-truncate',
          sortable: true,
          width: 166
        },
        {
          text: 'Channel',
          align: 'left',
          value: 'payment_channel',
          class: 'text-truncate',
          sortable: true,
          width: 95
        },
        {
          text: 'Order Status',
          align: 'left',
          value: 'status',
          sortable: true,
          width: 178
        },
        {
          text: 'Payment Status',
          align: 'left',
          value: 'payment_status',
          sortable: true,
          width: 190
        },
        {
          text: 'Payment Method',
          align: 'left',
          value: 'payment_method',
          sortable: true,
          width: 210
        },
        {
          text: 'Mobile No.',
          align: 'left',
          value: 'user_phone_number',
          sortable: true,
          width: 120
        },
        {
          text: 'Order Date/Time',
          align: 'left',
          value: 'create_date',
          sortable: true,
          width: 170
        },
        {
          text: 'Item Name',
          align: 'left',
          value: 'line_items',
          sortable: false,
          width: 190
        },
        {
          text: '',
          align: 'right',
          value: 'total_price',
          sortable: true,
          width: 165
        },
        {
          text: 'Payment Success Date/Time',
          align: 'left',
          value: 'paid_at',
          sortable: true,
          width: 170
        },
        {
          text: 'Refunded Date/Time',
          align: 'left',
          value: 'refunded_at',
          sortable: true,
          width: 170
        },
        {
          text: 'Updated Date/Time',
          align: 'left',
          value: 'update_date',
          sortable: true,
          width: 170
        },
        {
          text: 'Branch',
          align: 'left',
          value: 'org_codename',
          sortable: true,
          width: 100
        }
      ],
      pageSize: 25,
      itemsPerPage: [25, 50, 100],
      selectedStatus: 0,
      channelTextItems: channelText,
      paymentMethodItems: paymentMethod
    }
  },
  computed: {
    listStatus() {
      return [
        {
          name: 'All',
          value: ''
        },
        ...Object.entries(orderStatus).map(s => ({value: s[0], name: s[1]}))
      ]
    },
    ...mapState('order', [
      'filterPaymentSuccessDate',
      'filterRefundedDate',
      'isLoading',
      'isLoadingExport',
      'orderList',
      'orderCsv',
      'total'
    ])
  },
  created() {
    this.setListOrgInfo()
    this.fetch()
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    isPackageGroup(lineItem) {
      if (lineItem.product_type === productTypeEnum.PACKAGES) {
        return 'product_parent_title_th' in lineItem
      } else {
        return false
      }
    },
    getTitleByCode(productContent, code) {
      const products = productContent.filter(p => p.code === code)
      return products.length > 0 ? products[0].title : ''
    },
    generateCsvText() {
      const genDateRange = dates =>
        dates.length > 0
          ? dates.map(d => convertDateBirthDate(d)).join(' to ')
          : '- to -'
      const paymentSuccessDataText = genDateRange(this.filterPaymentSuccessDate)
      const refundedDateText = genDateRange(this.filterRefundedDate)
      const header = `รายงานสรุปยอดขาย\nPayment success date ${paymentSuccessDataText}\nRefunded date ${refundedDateText}\n\n`
      return `${header}${this.orderCsv}`
    },
    async handleExport() {
      await this.fetchExportCsv()
      this.orderCsvData = this.generateCsvText()
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    handlerClick(value) {
      this.$router.push({name: 'order-detail', params: {orderId: value.id}})
    },
    textSearch(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, () => {
        this.setTextSearch(this.search.trim())
        this.fetch()
      })
    },
    clearSearch() {
      this.search = ''
      this.options.page = 1
      this.page = 1
      this.fetch(this.page)
    },
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
    dateFmt: date => convertDate(date),
    handleChangeStatus(value) {
      const selectedValue = this.listStatus[value].value
      this.setFilterStatus(selectedValue)
      this.fetch()
    },
    ...mapActions('order', [
      'fetch',
      'fetchExportCsv',
      'resetList',
      'setFieldSort',
      'setFilterStatus',
      'setListOrgInfo',
      'setPage',
      'setPageSize',
      'setSort',
      'setTextSearch'
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        let selectedSortBy = ''
        if (sortBy.length > 0) {
          selectedSortBy =
            sortBy[0] === 'user_first_name' ? 'user_full_name' : sortBy[0]
        }
        this.setPage(page)
        this.setFieldSort(selectedSortBy)
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
    search: {
      handler() {
        this.options.page = 1
      }
    },
    selectedStatus: {
      handler() {
        this.options.page = 1
      }
    }
  }
}
</script>

<style lang="scss">
.order-table.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 82px;
}
.order-table tr {
  cursor: pointer;
}

.order-table td {
  max-height: 129px !important;
  min-height: 129px !important;
  height: 129px !important;
  vertical-align: top;
  padding-top: 12px !important;
  .v-list-item__content {
    padding-top: 0;
  }
}
</style>
